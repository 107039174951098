import type { Spacings } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const spacing: Spacings = {
  0: '0',
  1: '2px',
  2: '4px',
  3: '8px',
  4: '12px',
  5: '16px',
  6: '20px',
  7: '24px',
  8: '32px',
  9: '40px',
  10: '64px',
  11: '96px',
  12: '128px',
  13: '156px',
  14: '192px',
  15: '256px',
};
