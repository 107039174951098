export default new Map<string, { var: string; assignDefault?: string[] }>([
  [
    'labelColor',
    {
      var: 'components.label.states.default.color',
    },
  ],
  [
    'labelFont',
    {
      var: 'components.label.size.default.typography',
    },
  ],
]);
