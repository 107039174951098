import { useTheme } from 'styled-components';
import type { IconProps } from '../types';
const IcoInfo16 = ({ 'aria-label': ariaLabel, color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={16}
      height={16}
      fill={theme.color[color]}
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      aria-label={ariaLabel}
      className={className}
      role="img"
      data-colored={false}
    >
      <path
        d="M7.569 1.229a6.81 6.81 0 0 0-4.854 2.509 6.796 6.796 0 0 0-1.41 5.409 6.834 6.834 0 0 0 3.383 4.784 6.86 6.86 0 0 0 4.459.764 6.808 6.808 0 0 0 5.226-4.35 6.78 6.78 0 0 0-.728-6.118 7.29 7.29 0 0 0-1.358-1.493C11.22 1.874 9.818 1.312 8.56 1.24c-.535-.031-.637-.032-.991-.011m1.257 1.294c2.385.347 4.28 2.246 4.655 4.665a7.113 7.113 0 0 1 0 1.624 5.561 5.561 0 0 1-3.39 4.318 5.51 5.51 0 0 1-5.902-1.116 5.52 5.52 0 0 1-1.67-3.202A5.298 5.298 0 0 1 2.473 8c0-.578.04-.906.169-1.408.578-2.241 2.572-3.92 4.884-4.112.348-.029.94-.009 1.3.043M7.691 4.224a.728.728 0 0 0-.447.696c0 .23.046.343.211.519.367.392 1.003.285 1.243-.209a.833.833 0 0 0 .004-.619c-.171-.379-.642-.559-1.011-.387m-.784 2.543a.63.63 0 0 0-.348.692c.065.291.284.47.615.503l.184.018.007 1.497.008 1.496.063.127c.08.162.219.277.402.331a.621.621 0 0 0 .721-.324l.068-.132.008-1.774c.008-1.92.001-2.05-.123-2.214a.552.552 0 0 0-.259-.215c-.095-.045-.169-.051-.68-.051-.488 0-.587.007-.666.046"
        fillRule="evenodd"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoInfo16;
