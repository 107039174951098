export default new Map<string, { var: string; assignDefault?: string[] }>([
  [
    'dropdownBg',
    {
      var: 'components.dropdown.bg',
    },
  ],
  [
    'dropdownHoverBg',
    {
      var: 'components.dropdown.hover.bg',
    },
  ],
  [
    'dropdownBorderColor',
    {
      var: 'components.dropdown.borderColor',
    },
  ],
  [
    'dropdownBorderWidth',
    {
      var: 'components.dropdown.borderWidth',
    },
  ],
  [
    'dropdownBorderRadius',
    {
      var: 'components.dropdown.borderRadius',
    },
  ],
  [
    'dropdownElevation',
    {
      var: 'components.dropdown.elevation',
    },
  ],
  [
    'dropdownColorPrimary',
    {
      var: 'components.dropdown.colorPrimary',
    },
  ],
  [
    'dropdownColorSecondary',
    {
      var: 'components.dropdown.colorSecondary',
    },
  ],
  [
    'dropdownFooterBg',
    {
      var: 'components.dropdown.footer.bg',
    },
  ],
]);
