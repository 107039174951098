export enum RiskSignalAttribute {
  // TODO(argoff): remove
  name = 'name',
  dob = 'dob',
  ssn = 'ssn',
  address = 'address',
  streetAddress = 'street_address',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country',
  email = 'email',
  phoneNumber = 'phone_number',
  ipAddress = 'ip_address',
  document = 'document',
  selfie = 'selfie',
  device = 'device',
  native_device = 'native_device',
}

export default RiskSignalAttribute;
