import type { ZIndexes } from '../types';

const zIndexes: ZIndexes = {
  sticky: 5,
  tooltip: 10,
  dropdown: 10,
  overlay: 15,
  dialog: 20,
  bottomSheet: 30,
  confirmationOverlay: 25,
  confirmationDialog: 30,
  popover: 20,
  drawer: 25,
  toast: 30,
};

export default zIndexes;
