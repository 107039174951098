export default new Map<string, { var: string; assignDefault?: string[] }>([
  [
    'hintColor',
    {
      var: 'components.hint.states.default.color',
    },
  ],
  [
    'hintErrorColor',
    {
      var: 'components.hint.states.error.color',
    },
  ],
  [
    'hintFont',
    {
      var: 'components.hint.size.default.typography',
    },
  ],
]);
