const fonyFamilyVariables = new Map<string, { var: string; assignDefault?: string[] }>([
  [
    'fontFamily',
    {
      var: 'fontFamily.default',
    },
  ],
]);

export default fonyFamilyVariables;
