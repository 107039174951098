import type { HTMLAttributes } from 'react';

import type { BoxStyleProps } from './box.types';

export const styleKeyProps: string[] = [
  'alignContent',
  'alignItems',
  'alignSelf',
  'backdropFilter',
  'backfaceVisibility',
  'backgroundBlendMode',
  'backgroundClip',
  'backgroundColor',
  'backgroundImage',
  'backgroundOrigin',
  'backgroundPosition',
  'backgroundRepeat',
  'backgroundSize',
  'borderCollapse',
  'borderColor',
  'borderPosition',
  'borderRadius',
  'borderSpacing',
  'borderStyle',
  'borderWidth',
  'borderBottomWidth',
  'borderTopWidth',
  'borderLeftWidth',
  'borderRightWidth',
  'bottom',
  'boxSizing',
  'clear',
  'clip',
  'color',
  'columnCount',
  'columnFill',
  'columnGap',
  'columnRule',
  'columnRuleColor',
  'columnRuleStyle',
  'columnRuleWidth',
  'columns',
  'columnSpan',
  'columnWidth',
  'contain',
  'content',
  'counterIncrement',
  'counterReset',
  'counterSet',
  'cursor',
  'display',
  'elevation',
  'emptyCells',
  'filter',
  'flex',
  'flexBasis',
  'flexDirection',
  'flexFlow',
  'flexGrow',
  'flexShrink',
  'flexWrap',
  'float',
  'fontStyle',
  'Gap',
  'gap',
  'grid',
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumn',
  'gridColumnEnd',
  'gridColumnGap',
  'gridColumnStart',
  'gridGap',
  'gridRow',
  'gridRowEnd',
  'gridRowGap',
  'gridRowStart',
  'gridTemplate',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'height',
  'hyphens',
  'justifyContent',
  'justifyItems',
  'justifySelf',
  'left',
  'letterSpacing',
  'lineBreak',
  'lineHeight',
  'listStyle',
  'listStyleImage',
  'listStylePosition',
  'listStyleType',
  'margin',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'marginInline',
  'marginBlock',
  'maxHeight',
  'maxWidth',
  'minHeight',
  'minWidth',
  'objectFit',
  'objectPosition',
  'opacity',
  'order',
  'outline',
  'overflow',
  'overflowWrap',
  'overflowX',
  'overflowY',
  'padding',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'paddingTop',
  'paddingInline',
  'paddingBlock',
  'perspective',
  'perspectiveOrigin',
  'placeContent',
  'placeItems',
  'placeSelf',
  'pointerEvents',
  'position',
  'resize',
  'right',
  'rirection',
  'risplay',
  'rowGap',
  'scrollBehavior',
  'tableLayout',
  'tabSize',
  'textAlign',
  'textAlignLast',
  'textDecoration',
  'textDecorationColor',
  'textDecorationLine',
  'textDecorationStyle',
  'textIndent',
  'textJustify',
  'textOverflow',
  'textShadow',
  'textTransform',
  'top',
  'transform',
  'transformOrigin',
  'transition',
  'transitionDuration',
  'transitionProperty',
  'transitionTimingFunction',
  'userSelect',
  'verticalAlign',
  'visibility',
  'whiteSpace',
  'width',
  'wordBreak',
  'wordSpacing',
  'wordWrap',
  'zIndex',
];

export const filterProps = (props: BoxStyleProps) => {
  const styleProps: Partial<BoxStyleProps> = {};
  const elProps: Partial<HTMLAttributes<Element>> = {};

  Object.keys(props).forEach(key => {
    if (styleKeyProps.includes(key)) {
      // @ts-ignore
      styleProps[key] = props[key];
    } else {
      // @ts-ignore
      elProps[key] = props[key];
    }
  });
  return { styleProps, ...elProps };
};
