export default new Map<string, { var: string; assignDefault?: string[] }>([
  [
    'containerBg',
    {
      var: 'components.bifrost.container.bg',
    },
  ],
  [
    'containerBorder',
    {
      var: 'components.bifrost.container.border',
    },
  ],
  [
    'containerBorderRadius',
    {
      var: 'components.bifrost.container.borderRadius',
    },
  ],
  [
    'containerElevation',
    {
      var: 'components.bifrost.container.elevation',
    },
  ],
  [
    'containerWidth',
    {
      var: 'components.bifrost.container.width',
    },
  ],
  // Deprecated
  [
    'dialogBg',
    {
      var: 'components.bifrost.container.bg',
    },
  ],
  // Deprecated
  [
    'dialogBorderRadius',
    {
      var: 'components.bifrost.container.borderRadius',
    },
  ],
  // Deprecated
  [
    'dialogBoxShadow',
    {
      var: 'components.bifrost.container.elevation',
    },
  ],
]);
