enum CorporationType {
  agent = 'agent',
  c_corporation = 'c_corporation',
  s_corporation = 's_corporation',
  b_corporation = 'b_corporation',
  llc = 'llc',
  llp = 'llp',
  non_profit = 'non_profit',
  partnership = 'partnership',
  sole_proprietorship = 'sole_proprietorship',
  trust = 'trust',
  unknown = 'unknown',
}

export default CorporationType;
