import type { BorderRadiuses } from '../types';

export const borderRadius: BorderRadiuses = {
  none: '0px',
  sm: '4px',
  default: '6px',
  lg: '12px',
  xl: '16px',
  full: '100px',
};

export const borderWidth = {
  0: '0px',
  1: '1px',
  2: '2px',
};
