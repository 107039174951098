export const buttonHeights = {
  large: '40px',
  default: '32px',
  compact: '28px',
};

export const inputHeights = {
  textArea: '140px',
  large: '48px',
  default: '40px',
  compact: '32px',
};

export const linkButtonHeights = {
  default: '24px',
  compact: '20px',
  tiny: '20px',
  xTiny: '16px',
  xxTiny: '16px',
};
