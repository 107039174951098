enum VisaKind {
  e1 = 'e1',
  e2 = 'e2',
  e3 = 'e3',
  f1 = 'f1',
  g4 = 'g4',
  h1b = 'h1b',
  l1 = 'l1',
  o1 = 'o1',
  tn1 = 'tn1',
  other = 'other',
}

export default VisaKind;
