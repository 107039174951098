const radioSelectVariables = new Map<string, { var: string }>([
  [
    'radioSelectBg',
    {
      var: 'components.radioSelect.bg',
    },
  ],
  [
    'radioSelectBorderRadius',
    {
      var: 'components.radioSelect.borderRadius',
    },
  ],
  [
    'radioSelectBorderWidth',
    {
      var: 'components.radioSelect.borderWidth',
    },
  ],
  [
    'radioSelectBorderColor',
    {
      var: 'components.radioSelect.borderColor',
    },
  ],
  [
    'radioSelectColor',
    {
      var: 'components.radioSelect.color',
    },
  ],
  [
    'radioSelectHoverColor',
    {
      var: 'components.radioSelect.hover.initial.color',
    },
  ],
  [
    'radioSelectSelectedColor',
    {
      var: 'components.radioSelect.selected.color',
    },
  ],
  [
    'radioSelectHoverBg',
    {
      var: 'components.radioSelect.hover.bg',
    },
  ],
  [
    'radioSelectHoverBorderColor',
    {
      var: 'components.radioSelect.hover.borderColor',
    },
  ],
  [
    'radioSelectSelectedBg',
    {
      var: 'components.radioSelect.selected.bg',
    },
  ],
  [
    'radioSelectSelectedBorderColor',
    {
      var: 'components.radioSelect.selected.borderColor',
    },
  ],
  [
    'radioSelectComponentsIconBg',
    {
      var: 'components.radioSelect.components.icon.bg',
    },
  ],
  [
    'radioSelectComponentsIconHoverBg',
    {
      var: 'components.radioSelect.components.icon.hover.bg',
    },
  ],
  [
    'radioSelectComponentsIconSelectedBg',
    {
      var: 'components.radioSelect.components.icon.selected.bg',
    },
  ],
]);

export default radioSelectVariables;
